<template>
  <div>
    <el-dialog title="添加班级"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="60px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="学校"
                          prop="school_id">
              <el-select v-model="form.school_id"
                         style="width:100%"
                         filterable
                         placeholder="请选择">
                <el-option v-for="item in schoolList"
                           :key="item.school_id"
                           :label="item.school_name"
                           :value="item.school_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24"
                  style="text-align:center">
            <el-button type="primary"
                       @click="importData">
              导入班级
            </el-button>
          </el-col>
      
        </el-row>
      </el-form>
<!-- 
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        school_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      },
      classList: ['']
    }
  },
  computed: {
    schoolList () {
      return this.$parent.schoolList.filter(item => Number(item.school_id))
    }
  },
  methods: {
    handleClose () {
      this.classList = ['']
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    // 导入实际班级
    importData () {
      console.log('importData',this.form.school_id);
      if (!this.form.school_id ) {
        this.$message.warning('请先选择学校')
        return
      }
      let file;
      this.$get_file({ limit: 1000, fileType: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }).then(res => {
        file = res
        let formData = new FormData()
        // let form = JSON.parse(JSON.stringify(this.form))
        // for (const key in form) {
        //   formData.append(key, this.form[key])
        // }
        formData.append('file', file)
        formData.append('edu_school_id', this.form.school_id)
        formData.append('sign', this.$getMd5Form(file))
        this.$http({
          url: '/api/v2/bureau/import_class',
          method: 'post',
          data: formData
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })
      })

    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let classList = this.classList.filter(item => item)
        let form = {
          school_id: this.form.school_id,
          grade_id: this.form.grade_id,
          class_name: classList
        }
        this.$http({
          url: '/api/v2/bureau/class_create',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '修改成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })
      })
    },
    add () {
      this.classList.push('')
    },
    delClass (index) {
      this.classList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
.warp {
  display: flex;
  margin-top: 20px;
  div {
    &:nth-child(1) {
      flex: 1;
      margin-left: 8px;
    }
    &:nth-child(2) {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>